import React, {
  useCallback, 
  useState, 
  useMemo,
} from 'react';
import './App.css';
import { withAuthenticator } from 'aws-amplify-react';
import Amplify,{ Auth,API } from 'aws-amplify';

declare const awsConfig: {};

Amplify.configure(awsConfig);

function App() {
  const [username, setUserName] = useState<string>("");
  const [ipaddr, setIPAddr] = useState<string>("");

  const signOut = useCallback(() => {
    Auth.signOut()
    .then((data) => {
      setUserName("");
      setIPAddr("");
    })
    .catch((err) => {
      console.log(err);
      alert('サインアウトに失敗しました');
      return;
    });
  }, [])
  const enter = useCallback(async () => {
    const apiName = "llabguard";   // awsconfigに記載した名称
    const path = "/entry";
    const init = {
      headers: {Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`}
    };
    API.get(apiName, path, init)
    .then(res => {
      setUserName(res.username);
      setIPAddr(res.source_ip);
      console.log(res);
    })
    .catch(err => {
      console.log(err);
      alert('失敗しました');
    })
  }, [])

  const InfoTable = useMemo(() => {
    if (username && ipaddr) {
      return (
        <table>
          <tbody>
          <tr>
            <th>username</th>
            <td>{username}</td>
          </tr>
          <tr>
            <th>ipaddr</th>
            <td>{ipaddr}</td>
          </tr>
          </tbody>
        </table>
      )
    }
    return (<></>)
  }, [username, ipaddr])

  return (
    <div className="App">
      <div>
        <button onClick={enter}>enter</button>
        <button onClick={signOut}>sign out</button>
      </div>
      <div>
        {InfoTable}
      </div>
    </div>
  );
}

export default withAuthenticator(App);
